import { createContext, ReactNode, useContext, useState, FC, useEffect, useMemo } from 'react'
import {
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    getAuth,
    User,
    UserCredential,
    signInWithCustomToken,
    updateProfile,
    ParsedToken,
} from 'firebase/auth'
import { auth, functions } from '../../../firebase_config/firebase_config'
import { useRouter } from 'next/router'
import { getUser, getUserByEmail, updateUser } from '../../../api/firestore/users'
import { UserData } from '../../../types'
import { getClientSubscriptions } from '../../../api/firestore/clients'
import LogRocket from 'logrocket'
import { httpsCallable } from 'firebase/functions'
import { DocumentData, useDoc } from '@tatsuokaniwa/swr-firestore'
import { setAuthToken } from '../../../api/axios'
import { ChatWidget } from '@clickconnector/widget-sdk'

interface IAuthData {
    currentUser: User | null
    userDB: DocumentData<UserData> | null
    claims: any
    loadingAuth: boolean
    handleSignIn: (email: string, password: string) => Promise<void>
    handleSignInWithCustomToken: (token: string) => Promise<void>
    handleSignOut: () => Promise<void>
    handleUpdatePassword: (newPassword: string) => Promise<void>
    handleUpdateProfile: ({ displayName, photoURL }: { displayName?: string; photoURL?: string }) => Promise<void>
    fromViscap: boolean
    isManualLogout: boolean
    impersonateUser: (email: string) => Promise<void>
    stopImpersonating: () => Promise<void>
    isImpersonating: boolean
}

export const AuthContext = createContext<IAuthData>(null!)

export const useAuth = () => {
    return useContext(AuthContext)
}

const impersonate_v2 = httpsCallable<{ email: string }, { impersonating: string }>(functions, 'impersonate_v2')

const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    const [loadingAuth, setLoadingAuth] = useState<boolean>(true)

    const [fromViscap, setFromViscap] = useState(false)
    const [claims, setClaims] = useState<ParsedToken>({})
    const [manualLogout, setManualLogout] = useState(false)
    const [userId, setUserId] = useState<string | null>(null)

    const isImpersonating = useMemo(() => claims.sub !== userId, [claims, userId])

    const { data: userDB = null } = useDoc<UserData>(
        currentUser
            ? {
                  path: `users/${userId}`,
                  parseDates: ['removeOn'],
              }
            : null
    )

    const router = useRouter()

    const impersonateUser = async (email: string) => {
        const res = await impersonate_v2({ email })
        setUserId(res.data.impersonating)
    }

    const stopImpersonating = async () => {
        await impersonate_v2({ email: '' })
        setUserId(currentUser.uid)
    }

    const handleSignInWithCustomToken = async (token: string) => {
        try {
            await signInWithCustomToken(auth, token)
        } catch (err) {
            console.log(err)
            throw new Error('There was an error attempting to sign you in')
        }
    }

    const handleSignIn = async (email: string, password: string) => {
        setLoadingAuth(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (err) {
            if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
                throw new Error('There was an error attempting to sign you in: Wrong email or password.')
            }
            throw new Error('There was an error attempting to sign you in')
        }
    }

    const handleUpdatePassword = async (newPassword: string) => {
        try {
            const updatePassword = httpsCallable(functions, 'changePassword')
            await updatePassword({ uid: currentUser.uid, newPassword: newPassword })
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleSignOut = async () => {
        try {
            console.log('sign out')

            await signOut(auth)

            setCurrentUser(null)
            setAuthToken(null)
            setManualLogout(true)
            setLoadingAuth(false)

            sessionStorage.setItem('currentUserEmail', null)
            sessionStorage.setItem('currentUserId', null)

            ChatWidget.resetSession()

            const script = document.getElementById('cc-widget-script')
            if (script) {
                script.remove()
            }
        } catch (err) {
            console.log(err)
            throw new Error('There was an issue signing you out')
        }
    }

    const handleUpdateProfile = async (params) => {
        try {
            await updateProfile(currentUser, { ...params })
        } catch (error) {
            throw new Error(error)
        }
    }

    useEffect(() => {
        const activeUid = claims?.impersonating
        if (activeUid) {
            setUserId(activeUid)
        }
    }, [claims])

    useEffect(() => {
        if (!currentUser) {
            return
        }

        setUserId(currentUser.uid)
    }, [currentUser])

    useEffect(() => {
        const unsubAuthState = auth.onAuthStateChanged(async (user) => {
            setLoadingAuth(true)

            const setNoneUser = async () => {
                console.log('no user!')

                setCurrentUser(null)
                setAuthToken(null)

                if (router.pathname.startsWith('/admin')) {
                    router.push('/login')
                }
            }

            if (auth.currentUser?.isAnonymous) {
                console.log('anonymous user')
                return
            }

            if (user) {
                try {
                    const idToken = await user.getIdToken()
                    setAuthToken(idToken)
                    const userDBData: any = await getUser(user.uid)
                    if (userDBData) {
                        if (!('email' in userDBData)) {
                            await setNoneUser()
                            return
                        }

                        const fromViscap_ = userDBData.email.endsWith('@viscapmedia.com')
                        setFromViscap(fromViscap_)

                        setCurrentUser(user)

                        const idTokenResult = await auth.currentUser.getIdTokenResult(true)
                        const claims = idTokenResult.claims

                        setClaims(claims)

                        sessionStorage.setItem('currentUserEmail', userDBData.email)
                        sessionStorage.setItem('currentUserId', userDBData.uid)

                        LogRocket.identify(userDBData.uid, { email: userDBData.email })

                        const [firstName, lastName] = userDBData.displayName.split(' ')

                        ChatWidget.identify({
                            id: userDBData.uid,
                            firstName: firstName || '',
                            lastName: lastName || '',
                            primaryEmail: userDBData.email,
                            primaryMobile: String(userDBData.phoneNumber || ''),
                        })

                        console.log('notice: user is logged in')
                    }
                } catch (err) {
                    console.log(err)
                    // throw new Error('Could not retrieve your information from our records')
                    if (router.pathname.includes('admin')) {
                        await router.push('/login')
                    }
                }
            } else {
                await setNoneUser()
            }

            setLoadingAuth(false)
        })

        const unsubTokenChanged = auth.onIdTokenChanged(async (user) => {
            if (user) {
                try {
                    const idToken = await user.getIdToken()
                    setAuthToken(idToken)
                } catch (error) {
                    console.error('Error refreshing token:', error)
                }
            } else {
                setAuthToken(null)
            }
        })

        return () => {
            unsubAuthState()
            unsubTokenChanged()
        }
    }, [router])

    const value = {
        currentUser,
        userDB,
        claims,
        loadingAuth,
        handleSignIn,
        handleSignInWithCustomToken,
        handleSignOut,
        handleUpdatePassword,
        handleUpdateProfile,
        fromViscap,
        isManualLogout: manualLogout,
        impersonateUser,
        stopImpersonating,
        isImpersonating,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
